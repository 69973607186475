
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const store = namespace("store");
const base = namespace("base");
const campaign = namespace("campaign");
@Component({})
@Table("loadListData")
export default class LotteryList extends Vue {
  dayjs = dayjs;
  @base.Action getDictionaryList;
  @campaign.Action querylotteryList;
  @campaign.Action updateLotteryBeEnd;
  @store.State storeData;
  emptyToLine = emptyToLine;
  t = t;
  splitThousands = splitThousands;
  orderList: any[] = [];
  searchForm = {
    campaignName: "",
    campaignStatus: ""
  };
  showComfirmPayment: boolean = false;
  showRegisterInvoice: boolean = false;
  showLinkQr: boolean = false;
  orderStatusList: any[] = [];
  staffList: any[] = [];
  created() {
    this.init();
    this.loadListData();
  }
  get campaignFullUrl() {
    return `${process.env.VUE_APP_H5_SITE}/wx-jump?businessType=CAMPAIGN&campaignType=${this.activeRow.campaignType}&campaignCode=${this.activeRow.campaignCode}&storeCode=${this.storeData.storeCode}`;
  }
  init() {
    // 活动状态枚举值
    this.getDictionaryList("campaign_status").then(data => {
      this.campaignStatusList = data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.querylotteryList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.orderList = data.data.list;
      return data;
    });
  }
  get searchList() {
    return [
      {
        label: t("v210801.activity-name"),
        type: "input",
        value: "",
        prop: "campaignName"
      },
      {
        label: t("v210801.active-state"),
        type: "select",
        selectList: this.campaignStatusList,
        value: "",
        prop: "campaignStatus"
      }
    ];
  }
  campaignStatusList: any = [];
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  activeRow: any = {};
  handleComfirmCommand(command, row) {
    if (command === "push") {
      this.$router.push(`/marketing/campaign/send-lottery-qualification/${row.campaignCode}`);
    } else if (command === "edit") {
      this.$router.push(`/marketing/campaign/lottery-edit/${row.campaignCode}`);
    } else if (command === "end") {
      this.activeRow = row;
    } else if (command === "copy") {
      this.$router.push(`/marketing/campaign/lottery-add?copyCode=${row.campaignCode}`);
    } else if (command === "copyLink") {
      this.activeRow = row;
      this.showLinkQr = true;
      setTimeout(() => {
        this.qrCode(this.campaignFullUrl);
      }, 200);
    }
  }
  handleEndCampaign() {
    this.updateLotteryBeEnd({
      campaignCode: this.activeRow.campaignCode
    }).then(data => {
      this.$message.success(t("v210831.the-raffle-is"));
      this.loadListData();
    });
  }
  handleLinkData(row) {
    this.$router.push(`/marketing/campaign/lottery-analysis/${row.campaignCode}?campaignType=lottery`);
  }
  handleDownloadQR() {
    var imgs = document.getElementById("qrcode").getElementsByTagName("img");
    var a = document.createElement("a");
    a.download = "QR" + new Date().getTime() || t("v210801.download-picture-name");
    a.href = imgs[0].src;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  handleLinkOrderView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}`
    });
    window.open(href, "_blank");
  }
}
